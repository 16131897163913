
body{
  background-color:lightgrey;
}


.Me{
  width: 200px;
  height: auto;
}

.container{
  width: 100%
}
li{
  margin-top: 6px;
  margin-bottom: 6px;
}